import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SocialLinks = makeShortcode("SocialLinks");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      textAlign: 'center'
    }}>
      <SocialLinks justifyContent='center' fontSize={40} mdxType="SocialLinks" />
      <p>&nbsp;</p>
      <div>© {new Date().getFullYear()} Built with <a href="https://www.gatsbyjs.com">Gatsby</a></div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      